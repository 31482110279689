import React from "react"

import { accident, logo } from "../assets"

import "tachyons"
import "./saqtanu.css"
import Iframe from 'react-iframe'
import Helmet from "react-helmet";
import {withPrefix} from "gatsby";

const Saqtanu = () => {
  return (
    <div className="sq-layout">
        <noscript><Iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQSCVLC"
                          height="0" width="0" style="display:none;visibility:hidden"></Iframe></noscript>
      <Helmet>
        <script src={withPrefix("js/googletagmanager.js")} />
      </Helmet>
      <header className="sq-header flex justify-between">
        <a href="/">
          <img
            alt="logo"
            title="logo"
            src={logo}
            className="br2 mb0 header__logo"
          />
        </a>
        <nav className="sq-nav dn db-l">
          <a className="sq-nav_item sq-nav_item--active">ОГПО</a>
          <a className="sq-nav_item">КАСКО</a>
        </nav>
        <a className="sq-nav_burger"></a>
      </header>

      <div className="sq-sections">
        <section className="sq-section sq-section--main relative">
          <h1 className="sq-h1 w-40-l">Автострахование онлайн</h1>

          <p className="sq-text">
            Получи лучшее предложение от <br /> страховых компаний твоего города
          </p>

          <a className="sq-btn dib mb3" href="/saqtanu-calc">
            Рассчитать стоимость
          </a>

          <a className="sq-btn sq-btn--transp dn">Перезвоните мне</a>

          <img
            alt="Accident"
            title="Accident"
            src={accident}
            className="absolute sq-main_img"
          />
        </section>
      </div>
      <footer className="sq-footer">
        <a className="mr5">Оферта</a>
        <a
          className="underline"
          href="https://id.mkb.kz/#/esbd/home"
          target="_blank"
        >
          Проверить страховку в ЕСБД
        </a>
      </footer>
    </div>
  )
}

export default Saqtanu
